/* You can add global styles to this file, and also import other style files */

html {
  max-width: 100vw;
  overflow-x: hidden;
  padding-top: env(safe-area-inset-top);
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.app-navbar-notch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  height: env(safe-area-inset-top);
  background-color: #8d1412;
}

/*
  Custom position for informational snackbar
 */
.snackbarPos {
  min-width: 0px !important;
  max-width: 250px !important;
  left: 120px;
  top: 30px;
  position: fixed !important;

  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  animation-iteration-count: infinite;
}

@keyframes shake {
  10% {
    transform: translate3d(-1px, 0, 0);
  }

  20% {
    transform: translate3d(2px, 0, 0);
  }

  30% {
    transform: translate3d(-4px, 0, 0);
  }

  40% {
    transform: translate3d(4px, 0, 0);
  }

  50%, 100% {
    transform: translate3d(0px, 0, 0);
  }
}

.appMatCard {
  max-width: 400px;
  margin: 15% auto;
}

.appMatCard img {
  max-width: 200px;
}
